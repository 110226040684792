<template>
  <div>
    <b-form class="salestask" @submit.prevent>
      <b-row class="form-section">
        <b-col>
          <p>
            {{ trans('prospects.landingpage.salestask_block.salestask') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="margin-top-6">
          <b-form-group
            id="prospect_salestext_group"
            class="vary-wysiwyg"
            :label="trans('global.salestext')"
            label-for="prospect_salestext"
            spellcheck="false"
            trim
          >
            <editor-menu-bar v-slot="{ commands, isActive }" class="menubar" :editor="editor">
              <b-button-group>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                  @click="commands.heading({ level: 1 })"
                >
                  H1
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                  @click="commands.heading({ level: 2 })"
                >
                  H2
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                  @click="commands.heading({ level: 3 })"
                >
                  H3
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.bold() }"
                  @click="commands.bold"
                >
                  <Icon icon="bold"></Icon>
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.italic() }"
                  @click="commands.italic"
                >
                  <Icon icon="italic"></Icon>
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.strike() }"
                  @click="commands.strike"
                >
                  <Icon icon="strike"></Icon>
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.underline() }"
                  @click="commands.underline"
                >
                  <Icon icon="underline"></Icon>
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.bullet_list() }"
                  @click="commands.bullet_list"
                >
                  <Icon icon="ul"></Icon>
                </button>
                <button
                  class="menubar__button"
                  :class="{ 'is-active': isActive.ordered_list() }"
                  @click="commands.ordered_list"
                >
                  <Icon icon="ol"></Icon>
                </button>
              </b-button-group>
            </editor-menu-bar>
            <editor-content class="editor" :editor="editor" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button
            v-if="!saveInProgress"
            ref="salestaskblockSaveButton"
            variant="primary"
            size="md"
            class="margin-top-16 mx-auto my-5"
            @click="save"
          >
            <span>{{ trans('prospects.landingpage.salestask_block.save') }}</span>
          </b-button>
          <b-button
            v-else
            variant="primary"
            size="md"
            class="margin-top-16 mx-auto my-5"
            :style="{ width: saveButtonWidth }"
          >
            <b-spinner variant="white" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Heading,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline
  } from 'tiptap-extensions'

  import Icon from '../../../../../../Core/WysiwygIcons'

  const fb = require('../../../../../../../firebaseConfig')

  export default {
    name: 'LandingpageBlockSalestask',
    components: {
      EditorContent,
      EditorMenuBar,
      Icon
    },
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        editor: null,
        salesTaskHTML: '',
        saveInProgress: false,
        saveButtonWidth: false
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      // salesTask (): Sets the initial salestask data based on what's in the DB
      salesTask () {
        let html = ''

        if (this.block.data !== undefined && this.block.data.salestask !== undefined) {
          const latestSalestaskID = Object.keys(this.block.data.salestask).pop()
          html = this.block.data.salestask[latestSalestaskID].html
        }

        return html
      }
    },
    mounted () {
      this.editor = new Editor({
        extensions: [
          new Heading({ levels: [1, 2, 3] }),
          new Blockquote(),
          new OrderedList(),
          new BulletList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline()
        ],
        content: this.salesTask,
        onUpdate: ({ getHTML }) => {
          this.salesTaskHTML = getHTML()
        }
      })
    },
    beforeDestroy () {
      this.editor.destroy()
    },
    methods: {
      save () {
        console.log('Saving from SalesTask')
        console.log(this.block.key)
        const self = this
        this.saveButtonWidth = this.$refs.salestaskblockSaveButton
          ? (parseInt(this.$refs.salestaskblockSaveButton.clientWidth) + 2).toString() + 'px'
          : false
        this.saveInProgress = true

        const nextSalestaskId = this.block.data !== undefined &&
          this.block.data.salestask !== undefined &&
          Object.keys(this.block.data.salestask).length > 0
          ? parseInt(Object.keys(this.block.data.salestask).pop()) + 1
          : 0

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`landingpage.blocks.${self.block.key}.data.salestask.${nextSalestaskId}`]: {
            id: nextSalestaskId,
            createdOn: new Date(),
            deleted: false,
            html: self.salesTaskHTML,
            userId: self.userProfile.uid
          }
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.salestask_block.save_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.saveInProgress = false
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.salestask_block.save_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          this.saveInProgress = false
          console.log('Error while saving salestask:')
          console.log(err)
        })
      }
    }
  }
</script>
